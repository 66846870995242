<template>
  <div>
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      title="Change Password"
    />
    <span
      class="ma-0 changePassword"
    >
      <v-card 
        flat
      >
        <v-card-text class="px-2 py-0">
          <v-form 
            v-model="valid"
            :lazy-validation="lazy"
            ref="form"
          >
            <v-text-field
              class="mt-1"
              :type="show1 ? 'password' : 'text'"
              label="Current password"
              aria-label="current password field"
              v-model="currentPassword"
              :rules="rulesPassword"
            >
              <v-icon
                tabindex="0"
                class="pl-2"
                @click="show1 = !show1"
                :aria-label="show1 ? 'show current password':'hide current password'"
                slot="append"
                style="height:48px;width:48px"
              >
                {{ 
                  this.show1 ? 'mdi-eye' : 'mdi-eye-off' 
                }}
              </v-icon>
            </v-text-field>
            <v-text-field
              :type="show2 ? 'password' : 'text'"
              label="New password"
              aria-label="new password field"
              class="pt-2 pb-0"
              v-model="newPassword"
              :rules="rules"
            >
              <v-icon
                tabindex="0"
                :aria-label="show2 ? 'show new password':'hide new password'"
                @click="show2 = !show2"
                class="pl-2"
                slot="append"
                style="height:48px;width:48px"
              >
                {{ 
                  this.show2 ? 'mdi-eye' : 'mdi-eye-off'
                }}
              </v-icon>
            </v-text-field>
            <v-text-field
              :type="show3 ? 'password' : 'text'"
              label="Verify password"
              aria-label="verify password field"
              class="pt-2 pb-2"
              v-model="verifyPassword"
              :rules="verifyPasswordRules"
              @click:append="show3 = !show3"
            >
              <v-icon
                tabindex="0"
                :aria-label="show3 ? 'show verify password':'hide verify password'"
                @click="show3 = !show3"
                slot="append"
                class="pl-2"
                style="height:48px;width:48px"
              >
                {{ 
                  this.show3 ? 'mdi-eye' : 'mdi-eye-off'
                }}
              </v-icon>
            </v-text-field>
            <v-btn
              class="primary mt-3"
              style="width:100%;height:36px;"
              tile
              @click="changePassword"
              :disabled="!valid"
            >CHANGE PASSWORD</v-btn>
          </v-form>
          <p class="pt-5 text--default">DOE password policy:</p>
          <ul class="ml-n2 text--default">
            <li>Use at least 8 characters</li>
            <li>Use upper and lower case letters</li>
            <li>Include at least one number</li>
            <li>No previous passwords allowed</li>
            <li>Don't use common letter or number patterns (eg AAA, 123)</li>
            <li>Don't repeat characters more than once (eg AAA, 111)</li>
            <li>Don't use spaces</li>
          </ul>
          <p class="pt-5 text--default">Note: If you access your DoE email or other apps on a smartphone or tablet, you will need to manually change your password on those devices too.</p>
        </v-card-text>
      </v-card>
    </span>
  </div>
</template>
<script>
  import appBarMobile from "@/components/appBarMobile";
  export default {
    components: {
      appBarMobile,
    },
    methods:{
      validInput(){
        return this.$refs.form.validate();
      },
      changePassword(){
        if(this.validInput()){
          let passwordObj = {current_password: this.currentPassword,new_password: this.newPassword };
          this.$store.dispatch("changePassword",passwordObj);
          //eslint-disable-next-line
          NativeJS.changePasswordTapped();
        }
      }
    },
    data(){
      return {
        rulesPassword:[
          value => !!value || 'Current password required'
        ],
        rules: [
        value => !!value || 'New password required.',
        value => (value && value.length >= 8) || 'New password should have min 8 characters.',
        value => (value && value.length <= 15) || 'New password should have max 15 characters.',
        value => {
                  return value && value.indexOf(' ') == -1 || "spaces not allowed in New password."
        },
        value => {
                    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
                    return pattern.test(value) || "New password requires, at least one capital letter, lower letter and a number."
                }
        ],
        verifyPasswordRules:[
          value => !!value || 'Verify password required',
          value => (value && value == this.newPassword) || "Verify password do not match new password."
        ],
        show1: true,
        show2: true,
        lazy: true,
        valid: true,
        show3: true,
        currentPassword:'',
        verifyPassword:'',
        newPassword:''
      }
    }
  }
</script>
<style scoped>
.text--default{ color:#121212; }
::v-deep .v-input input{ max-height: 15px;}
::v-deep .v-text-field__slot{
  padding-top:15px;
}
</style>